// extracted by mini-css-extract-plugin
export var column = "UserDashboardNotifications__column__JfFHl";
export var container = "UserDashboardNotifications__container__oa2nq";
export var flex = "UserDashboardNotifications__flex__afMo4";
export var flexColumn = "UserDashboardNotifications__flexColumn__gUPmh";
export var gap1 = "UserDashboardNotifications__gap1__oXDXy";
export var gap2 = "UserDashboardNotifications__gap2__yq72X";
export var gap3 = "UserDashboardNotifications__gap3__MomhE";
export var gap4 = "UserDashboardNotifications__gap4__Vex7D";
export var gap5 = "UserDashboardNotifications__gap5__Eyg4y";
export var row = "UserDashboardNotifications__row__TnF6Z";
export var subscriptionOption = "UserDashboardNotifications__subscriptionOption__rklYQ";
export var subscriptions = "UserDashboardNotifications__subscriptions__REaeB";
export var subscriptionsContainer = "UserDashboardNotifications__subscriptionsContainer__PfqYZ";
export var subscriptionsExplanation = "UserDashboardNotifications__subscriptionsExplanation__Z06yZ";
export var subscriptionsRow = "UserDashboardNotifications__subscriptionsRow__YN5mO";
export var subscriptionsTitle = "UserDashboardNotifications__subscriptionsTitle__WrlZ5";